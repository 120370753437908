<script setup lang="ts">
const links = [
  {
    title: 'Creators',
    links: [
      { name: 'Radio Streaming', route: '/streaming/' },
      { name: 'Podcast', route: '/podcasting/' },
      { name: 'Radio Automation Software', route: '/automation/' },
      { name: 'Zeno Plans', route: '/pricing/' },
      { name: 'AudioClips AI', route: 'https://audioclips.ai/' },
    ],
  },
  {
    title: 'Partner With Us',
    links: [
      { name: 'Promote Your Station', route: 'https://zenoadvertising.com/' },
      { name: 'Zeno API', route: '/zenoapi/' },
      { name: 'App Network', route: '/network/' },
    ],
  },
  // {
  //   title: 'About us',
  //   links: [
  //     { name: 'What we do', route: 'https://zenomedia.com/services' },
  //     { name: 'Team', route: 'https://zenomedia.com/about' },
  //     { name: 'Contact', route: 'mailto:support@zenomedia.com' },
  //   ],
  // },
]

const socials = [
  {
    name: 'Facebook',
    icon: 'bi:facebook',
    route: 'https://www.facebook.com/zenodotfm',
  },
  {
    name: 'Instagram',
    icon: 'bi:instagram',
    route: 'https://www.instagram.com/zenofm',
  },
  {
    name: 'Twitter',
    icon: 'bi:twitter-x',
    route: 'https://twitter.com/zenodotfm',
  },

  {
    name: 'Youtube',
    icon: 'bi:youtube',
    route: 'https://www.youtube.com/@ZenoMedia',
  },
]
</script>

<template>
  <nav class="space-y-16 justify-self-end">
    <div class="flex sm:flex-row flex-col sm:space-x-24 sm:space-y-0 space-y-16 lg:justify-evenly">
      <div v-for="section in links" :key="section.title" class="space-y-8">
        <h3 class="text-zeno-gray">{{ section.title }}</h3>
        <ul class="space-y-3">
          <li v-for="(link, index) in section.links" :key="index">
            <a :href="link.route" target="_blank">
              <template v-if="link?.name">
                {{ link.name }}
              </template>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex space-x-8">
      <NuxtLink
        v-for="(social, index) in socials"
        :key="index"
        :href="social.route"
        target="_blank"
        :aria-label="social.name"
      >
        <Icon v-if="social.icon" size="28" :name="social.icon" />
        <span v-else> {{ social.name }}</span>
      </NuxtLink>
    </div>
  </nav>
</template>

<style scoped></style>
